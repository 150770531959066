



















































import ContentCard from "@/components/ContentCard.vue";
import Table from "@/components/Table.vue";
import { computed, defineComponent, provide } from "@vue/composition-api";
import { TableField } from "@/types/TableField";
import { useResult } from "@vue/apollo-composable";
import { useGetOrganizationsQuery } from "@/graphql/types";
import useProfile from "@/composables/useProfile";
import useNavigateToOrganization from "@/composables/navigation/useNavigateToOrganization";
import { useNumberFormat } from "@/composables/useNumberFormat";
import { ParentQueryKey } from "./symbols";

export default defineComponent({
  components: { ContentCard, Table },
  setup(_, { root }) {
    const { isCentralAdmin, isTechnicalAdmin } = useProfile();

    const { result, loading, error, document, variables } =
      useGetOrganizationsQuery();
    provide(ParentQueryKey, {
      query: document.value,
      variables: variables.value,
    });

    return {
      useNumberFormat,
      loading,
      error,
      editable: computed(() => isCentralAdmin.value || isTechnicalAdmin.value),
      useNavigateToOrganization,
      items: useResult(result, [], (data) => data.organizations.nodes),
      totalCount: useResult(result, 0, (data) => data.organizations.totalCount),
      fields: computed<TableField[]>(() => [
        {
          key: "name",
          filter: true,
          sortable: true,
          label: root.$tc("organizations.fields.name"),
        },
        {
          key: "vat",
          filter: true,
          sortable: true,
          label: root.$tc("organizations.fields.vat"),
        },
        {
          key: "users",
          filter: false,
          label: root.$tc("organizations.fields.users"),
          width: "10%",
        },
      ]),
    };
  },
});
